import { I_Post } from '@containers/Home/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createRoutine } from 'redux-saga-routines';
import { filterHotspotVisible } from '@utils/helper';
import { ContainerState } from './typesIndex';
import {
  I_FormCaptureConfig,
  I_CommentScene,
  I_Hotspot,
  I_Project,
  I_Scene,
  I_User,
} from './types';
import { onAnalyticsPlugins, onArrangeScenesIntoGroups } from './utils';

// The initial state of the App container
export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,

  dataMap: null,
  listFloorplan: [],
  listFormCapture: [],
  listCallToAction: [],

  configMap: null,
  configLogo: null,
  configNadir: null,
  configCopyRight: null,
  configFloorplan: null,
  configPopupIntro: null,
  configFormCapture: null,
  configCollaboration: null,
  configControlbar: null,
  configProtectPassword: null,
  configScreenshotCapture: null,

  listScene: [],
  listGroup: [],
  listPost: [],

  token: null,
  project: null,
  isFirstScene: null,

  configSoundProject: {
    on: null,
  },
  configAutoRotate: {
    on: false,
    enabled: false,
    speed: 2,
    waitTime: 2,
  },
  configAutoChangeScene: {
    on: false,
    enabled: false,
    waitTime: 15,
  },

  sceneSelected: null,

  hotspotSelected: null,
  prevSceneSelected: null,

  isVisibleFormCapture: null,
  formCaptureConfigSelected: null,
  hotspotSelectedTemporary: null,

  // USED FOR COLLABORATION ONLY
  infoUser: null,
  listCommentSceneSelected: [],
  isAllowToAccessOnCollaborationMode: null,
};

export const GET_DETAIL_PROJECT = createRoutine('App/getDetailProject');
export const GET_INFO_USER = createRoutine('App/getInfoUser');

export const CREATE_COMMENT_SCENE = createRoutine('App/createCommentScene');
export const UPDATE_COMMENT_SCENE = createRoutine('App/updateCommentScene');
export const DELELE_COMMENT_SCENE = createRoutine('App/deleteCommentScene');

const appSlice = createSlice({
  name: 'app',
  initialState,

  reducers: {
    setSceneSelected(
      state: ContainerState,
      action: PayloadAction<I_Scene | null>,
    ) {
      if (action.payload)
        if (state.isFirstScene === null) state.isFirstScene = true;
        else state.isFirstScene = false;

      state.sceneSelected = {
        ...action.payload,
        hotspots:
          action.payload?.hotspots?.filter(item =>
            filterHotspotVisible(item),
          ) || [],
      };
    },

    setIsAllowToAccessOnCollaborationMode(
      state: ContainerState,
      action: PayloadAction<boolean>,
    ) {
      state.isAllowToAccessOnCollaborationMode = action.payload || false;
    },

    setListCommentSceneSelected(
      state: ContainerState,
      action: PayloadAction<I_CommentScene[]>,
    ) {
      state.listCommentSceneSelected = action.payload || [];
    },

    createCommentScene(
      state: ContainerState,
      action: PayloadAction<I_CommentScene>,
    ) {
      const commentScenes = [action.payload, ...state.listCommentSceneSelected];
      state.listCommentSceneSelected = commentScenes;
      state.listScene = state.listScene.map(item =>
        item.id === state.sceneSelected.id
          ? { ...state.sceneSelected, commentScenes }
          : item,
      );
    },

    updateCommentScene(
      state: ContainerState,
      action: PayloadAction<I_CommentScene>,
    ) {
      const commentScenes = state.listCommentSceneSelected.map(item =>
        item.id === action.payload.id ? action.payload : item,
      );
      state.listCommentSceneSelected = commentScenes;
      state.listScene = state.listScene.map(item =>
        item.id === state.sceneSelected.id
          ? {
              ...state.sceneSelected,
              commentScenes: state.listCommentSceneSelected,
            }
          : item,
      );
    },

    deleteCommentScene(
      state: ContainerState,
      action: PayloadAction<I_CommentScene>,
    ) {
      const commentScenes = state.listCommentSceneSelected.filter(
        item => item.id !== action.payload.id,
      );
      state.listCommentSceneSelected = commentScenes;
      state.listScene = state.listScene.map(item =>
        item.id === state.sceneSelected.id
          ? { ...state.sceneSelected, commentScenes }
          : item,
      );
    },

    setToken(state: ContainerState, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },

    setConfigSoundProject(
      state: ContainerState,
      action: PayloadAction<Partial<ContainerState['configSoundProject']>>,
    ) {
      const { on } = action.payload;
      if (on !== undefined) state.configSoundProject.on = on;
    },

    setConfigAutoChangeScene(
      state: ContainerState,
      action: PayloadAction<Partial<ContainerState['configAutoChangeScene']>>,
    ) {
      const { enabled, waitTime, on } = action.payload;
      if (on !== undefined) state.configAutoChangeScene.on = on;
      if (enabled !== undefined) state.configAutoChangeScene.enabled = enabled;
      if (waitTime !== undefined)
        state.configAutoChangeScene.waitTime = waitTime;
    },

    setConfigAutoRotate(
      state: ContainerState,
      action: PayloadAction<Partial<ContainerState['configAutoRotate']>>,
    ) {
      const { on, enabled, waitTime, speed } = action.payload;
      if (on !== undefined) state.configAutoRotate.on = on;
      if (enabled !== undefined) state.configAutoRotate.enabled = enabled;
      if (waitTime !== undefined) state.configAutoRotate.waitTime = waitTime;
      if (speed !== undefined) state.configAutoRotate.speed = speed;
    },

    setprevSceneSelected(
      state: ContainerState,
      action: PayloadAction<I_Scene | null>,
    ) {
      state.prevSceneSelected = action.payload;
    },

    setHotspotSelected(
      state: ContainerState,
      action: PayloadAction<I_Hotspot | null>,
    ) {
      state.hotspotSelected = action.payload;
    },

    setHotspotSelectedTemporary(
      state: ContainerState,
      action: PayloadAction<I_Hotspot | null>,
    ) {
      state.hotspotSelectedTemporary = action.payload;
    },

    setIsVisibleFormCapture(state, action: PayloadAction<boolean>) {
      state.isVisibleFormCapture = action.payload;
      if (!action.payload) state.formCaptureConfigSelected = null;
    },

    setFormCaptureConfigSelelected(
      state,
      action: PayloadAction<I_FormCaptureConfig | null>,
    ) {
      state.formCaptureConfigSelected = action.payload || null;
    },

    setListPostUsedOnTour(state, action: PayloadAction<I_Post[]>) {
      state.listPost = action.payload || [];
    },

    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },

  extraReducers: builder => {
    builder.addCase(
      HYDRATE as string,
      (_state: ContainerState, action: PayloadAction<any>) =>
        action.payload.app,
    );

    builder
      .addCase(
        GET_DETAIL_PROJECT.TRIGGER,
        (
          state,
          // action: PayloadAction<{ id: string }>,
        ) => {
          state.loading = true;
          state.success = false;
          state.error = false;
        },
      )
      .addCase(
        GET_DETAIL_PROJECT.SUCCESS,
        (state, action: PayloadAction<I_Project>) => {
          const project = action.payload;
          const { scenes = [], groups = [], plugins = [] } = project;

          const { listScene, listGroup } = onArrangeScenesIntoGroups(
            scenes.filter(item => item.media),
            groups,
          );

          state.project = project;
          state.listScene = listScene;
          state.listGroup = listGroup;

          if (state.project.project_type === 'matterport')
            state.isFirstScene = true;

          // NOTE: Plugins
          const {
            dataMap,
            configMap,
            configLogo,
            configNadir,
            configPopupIntro,
            configProtectPassword,
            configCopyRight,
            listFloorplan,
            configFloorplan,
            listFormCapture,
            configFormCapture,
            configCollaboration,
            configControlbar,
            configScreenshotCapture,
            formCaptureConfigOnProject,
            listCallToAction,
          } = onAnalyticsPlugins(plugins);

          state.dataMap =
            state.project.project_type === 'matterport' &&
            state.project.lat &&
            state.project.lng
              ? {
                  markers: [
                    {
                      id: 'yX8lLEykClAEt9MollWiS',
                      lat: state.project.lat,
                      lng: state.project.lng,
                    },
                  ],
                }
              : dataMap;

          state.configMap = configMap;

          state.configLogo = configLogo;

          state.configNadir = configNadir;

          state.configPopupIntro = configPopupIntro;

          state.configProtectPassword = configProtectPassword;

          state.configCopyRight = configCopyRight;

          state.listFloorplan = listFloorplan;

          state.configFloorplan = configFloorplan;

          state.listFormCapture = listFormCapture;

          state.configFormCapture = configFormCapture;

          state.listCallToAction = listCallToAction;

          state.configCollaboration = configCollaboration;

          state.configControlbar = configControlbar;

          state.configScreenshotCapture = configScreenshotCapture;

          if (formCaptureConfigOnProject)
            state.formCaptureConfigSelected = formCaptureConfigOnProject;

          // NOTE: config globally
          state.configAutoChangeScene.on =
            project.config?.setting?.auto_change_scene?.enable || false;
          state.configAutoChangeScene.enabled =
            project.config?.setting?.auto_change_scene?.enable || false;
          state.configAutoChangeScene.waitTime =
            project.config?.setting?.auto_change_scene?.wait_time || 2;

          state.configAutoRotate.on =
            project.config?.setting?.auto_rotate?.enable || false;
          state.configAutoRotate.enabled =
            project.config?.setting?.auto_rotate?.enable || false;
          state.configAutoRotate.speed =
            project.config?.setting?.auto_rotate?.speed || 2;
          state.configAutoRotate.waitTime =
            project.config?.setting?.auto_rotate?.wait_time || 2;

          state.loading = false;
          state.success = true;
          state.error = false;
        },
      )
      .addCase(GET_DETAIL_PROJECT.FAILURE, state => {
        state.loading = false;
        state.success = false;
        state.error = true;
      });

    builder
      .addCase(GET_INFO_USER.TRIGGER, () => {})
      .addCase(
        GET_INFO_USER.SUCCESS,
        (state, action: PayloadAction<I_User>) => {
          state.infoUser = action.payload;
        },
      )
      .addCase(GET_INFO_USER.FAILURE, () => {});
  },
});

export const { actions, reducer, name: sliceKey } = appSlice;

export default reducer;
